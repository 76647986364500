import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import moment from 'moment'
import Master from 'src/models/Master'
import ReportingSpace from 'src/models/ReportingSpace'
import Extract from 'src/models/Extract'
import bus from 'src/bus'
import { camelToSnake } from './utils'

let timeout

Vue.use(Vuex)

moment.locale('fr')

const TWELVE_HOURS = 43200000

// This code is used all over and over again across the actions,
// that's why it has been refactored like this.
const refreshReportingSpace = commit => {
  return (response) => {
    // The query can be cancelled by vuex-persistedstate
    if (!response) return
    const { reportingSpace, resources } = response
    commit('addOrUpdateReportingSpace', { reportingSpace })
    commit('updatePageResource', { resources })
  }
}

function initialState () {
  return {
    activeRequests: 0,
    currentReportingSpaceId: null,
    currentExtract: null,
    email: null,
    firstName: null,
    lastName: null,
    authTime: 0,
    isAdmin: false,
    master: {},
    reportingSpacesById: {},
    allReportingSpacesById: {},
    advantages: [],
    pageResources: {},
    search: '',
    loading: false,
    loadingOnScroll: false,
    page: 0,
    endOfList: false,
    startTime: null,
    endTime: moment().valueOf()
  }
}

const store = new Vuex.Store({
  state: initialState,
  getters: {
    fullName (state) {
      return `${state.firstName} ${state.lastName}`
    },
    loggedIn (state) {
      return Date.now() - state.authTime < TWELVE_HOURS
    },
    uniquePicture (state) {
      if (Object.keys(state.reportingSpacesById).length === 0) return

      if (state.currentReportingSpaceId) {
        const picture = state.reportingSpacesById[state.currentReportingSpaceId].picture
        return picture || true
      } else return false
    },
    hasNoReportingSpace (state) {
      return Object.keys(state.reportingSpacesById).length === 0
    },
    isLoading (state) {
      return state.loading
    },
    isEndOfList (state) {
      return state.endOfList
    },
    search (state) {
      return state.search
    },
    hasActiveRequests (state) {
      return state.activeRequests > 0
    },
    startTime (state) {
      return state.startTime && moment(state.startTime)
    },
    endTime (state) {
      return state.endTime && moment(state.endTime)
    },
    reportingSpace: (state) => (id) => {
      return state.reportingSpacesById[id]
    },
    currentReportingSpace (state, getters) {
      return getters.reportingSpace(state.currentReportingSpaceId)
    },
    sortedReportingSpaces (state) {
      return Object.values(state.allReportingSpacesById)
    },
    sortedFilteredReportingSpaces (state) {
      return Object.values(state.reportingSpacesById)
    },
    parkingAdvantages (state) {
      return state.advantages.filter(({ type }) => type === 'parking')
    },
    hasParkingAdvantages (state, getters) {
      return getters.parkingAdvantages.length > 0
    }
  },
  mutations: {
    // reset to initial state
    // see https://github.com/vuejs/vuex/issues/1118#issuecomment-356286218
    reset (state) {
      // acquire initial state
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    updateMasterData (state, { master }) {
      state.master = {
        ...state.master,
        ...master
      }
    },
    setCurrentReportingSpaceId (state, { reportingSpaceId }) {
      state.currentReportingSpaceId = reportingSpaceId
    },
    incrementActiveRequests (state) {
      state.activeRequests += 1
    },
    decrementActiveRequests (state) {
      state.activeRequests -= 1
    },
    setUser (state, { authTime, email, firstName, lastName, isAdmin, usersExtractor, carpoolsExtractor }) {
      state.authTime = authTime
      state.email = email
      state.firstName = firstName
      state.lastName = lastName
      state.isAdmin = isAdmin === true
      state.usersExtractor = usersExtractor
      state.carpoolsExtractor = carpoolsExtractor
    },
    updateSearchField (state, { search }) {
      state.search = search
    },
    updateStartTime (state, { startTime }) {
      if (startTime instanceof moment) startTime = startTime.valueOf()
      state.startTime = startTime
    },
    updateEndTime (state, { endTime }) {
      if (endTime instanceof moment) endTime = endTime.valueOf()
      state.endTime = endTime
    },
    addOrUpdateReportingSpace (state, { reportingSpace }) {
      Vue.set(
        state.reportingSpacesById,
        reportingSpace.id,
        {
          ...state.reportingSpacesById[reportingSpace.id],
          ...reportingSpace
        }
      )
    },
    addOrUpdateAllReportingSpace (state, { reportingSpace }) {
      Vue.set(
        state.allReportingSpacesById,
        reportingSpace.id,
        {
          ...state.allReportingSpacesById[reportingSpace.id],
          ...reportingSpace
        }
      )
    },
    resetReportingSpaces (state) {
      state.reportingSpacesById = {}
    },
    updatePageResource (state, { resources }) {
      state.pageResources = {
        ...state.pageResources,
        ...resources
      }
    },
    deletePageResource (state) {
      state.pageResources = {}
    },
    setAdvantages (state, { advantages }) {
      state.advantages = advantages
    },
    setCurrentExtract (state, currentExtract) {
      state.currentExtract = currentExtract
    },
    updateCurrentExtract (state, currentExtract) {
      state.currentExtract = {
        ...state.currentExtract,
        ...currentExtract
      }
    }
  },
  actions: {
    async login ({ commit }, { email, password }) {
      const data = await Vue.$post('/login', { email, password })
      if (data === null) {
        return false
      }
      commit('setUser', { authTime: Date.now(), ...data })
      return true
    },
    logout ({ commit }) {
      commit('reset')
      return Vue.$get('/logout')
    },
    async googleConnect ({ commit }, { success, error }) {
      const redirectUri = 'postmessage'
      window.googleOAuthClient.callback = (response) => {
        try {
          Vue.$post('/administrators/google_oauth2_connect', {
            redirect_uri: redirectUri,
            code: response.code
          }).then((data) => {
            commit('setUser', {
              authTime: Date.now(),
              ...data
            })
            success()
          })
        } catch {
          error()
        }
      }
      try {
        await window.googleOAuthClient.requestCode({
          redirect_uri: redirectUri
        })
      } catch {
        error()
      }
      return true
    },
    async switchReportingSpace ({ commit, state, getters, dispatch }, { reportingSpaceId }) {
      if (state.currentReportingSpaceId === reportingSpaceId) return true
      commit('setCurrentReportingSpaceId', { reportingSpaceId })

      const reportingSpace = getters.currentReportingSpace
      if (reportingSpace) {
        commit('setAdvantages', { advantages: [] })
        const advantages = await ReportingSpace.getAdvantages(reportingSpaceId)
        commit('setAdvantages', { advantages })
        const launchDate = reportingSpace.launchDate
        commit('updateStartTime', { startTime: launchDate })
        commit('updateEndTime', { endTime: moment() })
        return true
      }
      commit('setCurrentReportingSpaceId', { reportingSpaceId: null })
      return false
    },
    async updateSearch ({ commit, dispatch }, { search }) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }

      commit('updateSearchField', { search: search })
      timeout = setTimeout(function () {
        dispatch('populateReportingSpaces', { force: true })
      }, 300)
    },
    async populateReportingSpaceById ({ commit, state, getters }, { id }) {
      if (state.reportingSpacesById[id]) return
      state.loading = true
      commit('resetReportingSpaces')
      const reportingSpace = await ReportingSpace.getById(id)
      commit('addOrUpdateReportingSpace', { reportingSpace })
      state.loading = false
    },
    async populateReportingSpaces ({ commit, state, getters }, { force }) {
      if (!force && !getters.hasNoReportingSpace) return
      state.page = 0
      state.loading = true
      state.loadingOnScroll = false
      state.endOfList = false
      commit('resetReportingSpaces')
      const reportingSpaces = await ReportingSpace.getAllByName(state.search, state.page)
      state.endOfList = reportingSpaces.length === 0
      reportingSpaces.forEach((reportingSpace) => {
        commit('addOrUpdateReportingSpace', { reportingSpace })
      })
      state.loading = false
    },
    async nextPage ({ commit, state }) {
      if (state.endOfList) return
      if (state.loadingOnScroll || state.loading) return
      state.loadingOnScroll = true
      state.page = state.page + 1
      const reportingSpaces = await ReportingSpace.getAllByName(state.search, state.page)
      state.endOfList = reportingSpaces.length === 0
      reportingSpaces.forEach((reportingSpace) => {
        commit('addOrUpdateReportingSpace', { reportingSpace })
      })
      state.loadingOnScroll = false
    },
    async loadReportingSpaceById ({ state, dispatch }, { id }) {
      await dispatch('populateReportingSpaceById', { id })
    },
    async loadReportingSpaces ({ state, dispatch }) {
      if (state.reportingSpacesById.length) return
      await dispatch('populateReportingSpaces', { force: true })
    },
    async updateEndTime ({ actions, commit, dispatch, state }, { startTime, endTime }) {
      if (state.endTime === endTime) return

      commit('updateEndTime', { endTime })
      bus.$emit('updateEndTime')
    },
    async updateRangeTime ({ actions, commit, dispatch, state }, { startTime, endTime }) {
      if (state.startTime === startTime && state.endTime === endTime) return

      commit('updateStartTime', { startTime })
      commit('updateEndTime', { endTime })
      bus.$emit('updateRangeTime')
    },
    async populateDashboardData ({ commit, getters }, id) {
      commit('deletePageResource')
      const { endTime } = getters
      await Promise.all([
        ReportingSpace.getEmployeesCount(id, { endTime }).then(
          refreshReportingSpace(commit)
        ),
        ReportingSpace.getEmployeesCumulated(id, { endTime }).then(
          refreshReportingSpace(commit)
        )
      ])
    },
    async populateRegistrationsData ({ commit, getters }, id) {
      commit('deletePageResource')
      const { startTime, endTime } = getters
      await Promise.all([
        ReportingSpace.getRegistrationsPerDay(id, { startTime, endTime }).then(
          refreshReportingSpace(commit)
        ),
        ReportingSpace.getRolesRepartition(id, { startTime, endTime }).then(
          refreshReportingSpace(commit)
        ),
        ReportingSpace.getFavoriteRoutesDistanceDistribution(id, { startTime, endTime }).then(
          refreshReportingSpace(commit)
        )
      ])
    },
    async populateNetworkData ({ commit, getters }, id) {
      commit('deletePageResource')
      const { endTime } = getters
      await Promise.all([
        ReportingSpace.getGoingPolylines(id, { endTime }).then(
          refreshReportingSpace(commit)
        ),
        ReportingSpace.getHubs(id, { endTime }).then(
          refreshReportingSpace(commit)
        ),
        ReportingSpace.getMatchingsDistribution(id, { endTime }).then(
          refreshReportingSpace(commit)
        ),
        ReportingSpace.getMatchingsCountDistribution(id, { endTime }).then(
          refreshReportingSpace(commit)
        )
      ])
    },
    async populateCofundingData ({ commit, getters }, id) {
      commit('deletePageResource')
      const { startTime, endTime } = getters
      await Promise.all([
        ReportingSpace.getCofunded(id, { startTime, endTime }).then(
          refreshReportingSpace(commit)
        )
      ])
    },
    async populateActivityAndCsrData ({ commit, getters }, id) {
      commit('deletePageResource')
      const { startTime, endTime } = getters
      await Promise.all([
        ReportingSpace.getTravels(id, { startTime, endTime }).then(
          refreshReportingSpace(commit)
        ),
        ReportingSpace.getCumulatedExchangedMessages(id, { startTime, endTime }).then(
          refreshReportingSpace(commit)
        ),
        ReportingSpace.getCumulatedSavedEuros(id, { startTime, endTime }).then(
          refreshReportingSpace(commit)
        ),
        ReportingSpace.getCumulatedExchangedMessages(id, { startTime, endTime }).then(
          refreshReportingSpace(commit)
        )
      ])
    },
    async populateIncentivesData ({ commit }, { id, cumulMonths, cumulPeriod, consoMonths, consoPeriod }) {
      commit('deletePageResource')
      await Promise.all([
        ReportingSpace.getCumulatedIncentives(id, cumulMonths, cumulPeriod).then(
          refreshReportingSpace(commit)
        ),
        ReportingSpace.getIncentivesConsumption(id, consoMonths, consoPeriod).then(
          refreshReportingSpace(commit)
        )
      ])
    },
    async populateMasterStatistics ({ commit }) {
      await Promise.all([
        Master.getUnsubscriptions().then(
          master => commit('updateMasterData', { master })
        ),
        Master.getDevices().then(
          master => commit('updateMasterData', { master })
        )
      ])
    },
    async postCustomExtract ({ state: { currentReportingSpaceId } }, { since, until }) {
      return Vue.$post(
        `/reporting_spaces/${currentReportingSpaceId}/extracts/custom`, {
          since: since.format('DD/MM/YYYY'),
          until: until.format('DD/MM/YYYY')
        }
      )
    },
    async postOriginDestinationExtract ({ state: { currentReportingSpaceId } }, { kind }) {
      return Vue.$post(
        `/reporting_spaces/${currentReportingSpaceId}/extracts/origin_destination`,
        {
          kind: camelToSnake(kind)
        }
      )
    },
    async postMatesExtract (_, { reportingScope, since, until, verifiedCompaniesOnly }) {
      return Vue.$post('/master/extract/mates', {
        reporting_scope: reportingScope,
        since,
        until,
        verified_companies_only: verifiedCompaniesOnly
      })
    },
    async postCarpoolingsExtract (_, { reportingScope, since }) {
      return Vue.$post('/master/extract/carpoolings', {
        reporting_scope: reportingScope,
        since
      })
    },
    // If there is already a pending extract, this action will get it and set
    // the related state correctly.
    async retrieveCurrentExtract ({ commit, state }) {
      commit('setCurrentExtract', await Extract.getCurrentExtract())
    },
    async pollCurrentExtractStatus ({ state, commit }) {
      if (!Extract.onPollExtractStatus) {
        commit('updateCurrentExtract', {
          status: await Extract.pollExtractStatus(state.currentExtract.extractId)
        })
      }
    },
    async clearCurrentExtract ({ state, commit }) {
      await Extract.clearExtract(state.currentExtract.extractId)
      commit('setCurrentExtract', null)
    }
  },
  plugins: [createPersistedState({
    reducer (state) {
      const reducedReportingSpacesById = {}
      Object.values(state.reportingSpacesById).forEach((reportingSpace) => {
        reducedReportingSpacesById[reportingSpace.id] = {
          id: reportingSpace.id,
          name: reportingSpace.name,
          picture: reportingSpace.picture,
          launchDate: reportingSpace.launchDate,
          dashboardOnly: reportingSpace.dashboardOnly,
          driversOriginDestinationExtractEnabled: reportingSpace.driversOriginDestinationExtractEnabled,
          driverOrPassengersOriginDestinationExtractEnabled: reportingSpace.driverOrPassengersOriginDestinationExtractEnabled,
          carpoolingsOriginDestinationExtractEnabled: reportingSpace.passengersOriginDestinationExtractEnabled,
          passengersOriginDestinationExtractEnabled: reportingSpace.carpoolingsOriginDestinationExtractEnabled,
          customExtractEnabled: reportingSpace.customExtractEnabled,
          incentivesTabEnabled: reportingSpace.incentivesTabEnabled
        }
      })
      return {
        ...state,
        pageResources: {},
        activeRequests: 0,
        reportingSpacesById: reducedReportingSpacesById
      }
    }
  })]
})

export default store
