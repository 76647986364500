import { toast } from 'toast-loader'

export default () => {
  toast.js('https://cdn.jsdelivr.net/npm/smoothscroll-polyfill@0.4.4')

  // Add Element.remove polyfill for IE
  if (typeof Element.remove !== 'function') {
    require('element-remove')
  }
}
