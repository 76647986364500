import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  // Forced to '/' instead of process.env.BASE_URL because the CDN configuration
  // from vue.config.js would configure it wrong.
  base: '/',
  routes: [
    {
      path: '/',
      name: 'reporting_spaces',
      meta: {
        requiresAuth: true
      },
      // route level code-splitting
      // this generates a separate chunk (dashboard.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "reporting-spaces" */ './views/ReportingSpaces.vue')
    },
    {
      path: '/reporting_spaces/:id',
      component: () => import(/* webpackChunkName: "reporting-space" */ './views/ReportingSpace.vue'),
      beforeEnter: async (to, from, next) => {
        await store.dispatch('loadReportingSpaceById', { id: to.params.id })
        if (await store.dispatch('switchReportingSpace', { reportingSpaceId: to.params.id })) {
          document.title = store.getters.currentReportingSpace.name + ' - Klaxit Insights'
          next()
        } else {
          // Couldn't find a reporting space
          next('/')
        }
      },
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          redirect: 'dashboard'
        },
        // Deprecated: support for old report URL in monthly reporting emails
        {
          path: 'funnel',
          redirect: 'dashboard'
        },
        {
          path: 'dashboard',
          component: () => import('./views/reporting-space/Dashboard.vue'),
          meta: {
            header: 'Bienvenue sur votre tableau de bord',
            singleDatepicker: true
          }
        },
        {
          path: 'cofunding',
          component: () => import('./views/reporting-space/Cofunding.vue'),
          meta: {
            header: 'Cofinancement'
          }
        },
        {
          path: 'signups', redirect: 'registrations'
        },
        {
          path: 'registrations',
          component: () => import('./views/reporting-space/Registrations.vue'),
          meta: {
            header: 'À propos des inscriptions'
          }
        },
        {
          path: 'network',
          component: () => import('./views/reporting-space/Network.vue'),
          meta: {
            header: 'Analyse du réseau',
            singleDatepicker: true
          }
        },
        {
          path: 'activity_and_csr',
          component: () => import('./views/reporting-space/ActivityAndCsr.vue'),
          meta: {
            header: 'Activité et RSE'
          }
        },
        {
          path: 'incentives',
          component: () => import('./views/reporting-space/Incentives.vue'),
          meta: {
            header: 'Suivi des incitatifs financiers',
            noDatepicker: true
          }
        },
        {
          path: 'extract',
          component: () => import('./views/reporting-space/Extract.vue'),
          meta: {
            header: 'Données à télécharger',
            noDatepicker: true
          }
        },
        {
          path: 'help',
          component: () => import('./views/reporting-space/Help.vue'),
          meta: {
            header: 'Comment pouvons-nous vous aider ?',
            noDatepicker: true
          }
        }
      ]
    },
    {
      path: '/master',
      name: 'master',
      meta: {
        requiresAuth: true
      },
      beforeEnter: (to, from, next) => {
        if (store.state.isAdmin) {
          next()
        } else {
          next('/')
        }
      },
      component: () => import(/* webpackChunkName: "master" */ './views/Master.vue'),
      children: [
        {
          path: '',
          redirect: 'statistics'
        },
        {
          path: 'statistics',
          name: 'statistics',
          component: () => import(/* webpackChunkName: "statistics" */ './views/master/Statistics.vue')
        },
        {
          path: 'extracts',
          name: 'extracts',
          component: () => import(/* webpackChunkName: "extracts" */ './views/master/Extracts.vue')
        },
        {
          path: 'demo',
          name: 'demo',
          component: () => import(/* webpackChunkName: "demo" */ './views/master/Demo.vue')
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      beforeEnter: (to, from, next) => {
        if (store.getters.loggedIn) {
          next(from.fullPath)
        } else {
          next()
        }
      },
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      beforeEnter: async (to, from, next) => {
        try {
          await Vue.$get(`/dashboard/authenticate?access_token=${to.query.access_token}`)
          next()
        } catch (e) {
          next('/')
        }
      },
      component: () => import(/* webpackChunckName: "dashboard" */ './views/Dashboard.vue')
    },
    // Redirection of old links
    {
      path: '/demo',
      redirect: '/master/demo'
    },
    {
      path: '/demo/area',
      redirect: '/master/demo'
    },
    {
      // We handle pages not found by redirecting to the ReportingSpaces view.
      path: '*',
      redirect: '/'
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    document.title = 'Klaxit Insights'
    if (store.getters.loggedIn) {
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next()
  }
})

export default router
