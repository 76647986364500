import Vue from 'vue'

const getUnsubscriptionsHolder = {}
const getDevicesHolder = {}

export default {
  getUnsubscriptions () {
    return Vue.$get(
      '/master/statistics/unsubscriptions',
      { holder: getUnsubscriptionsHolder }
    )
  },
  getDevices () {
    return Vue.$get(
      '/master/statistics/devices',
      { holder: getDevicesHolder }
    )
  }
}
