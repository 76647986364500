import Highcharts from 'highcharts'
import more from 'highcharts/highcharts-more'

/**
* Highcharts round corners: https://stackoverflow.com/a/31884817/6320039
*
* @param H Highcharts instance
*/
const roundCorners = H => {
  let curPercentage = []
  H.wrap(H.seriesTypes.column.prototype, 'translate', function (proceed) {
    let options = this.options

    let rTopLeft = options.borderRadius || 0
    let rTopRight = options.borderRadius || 0
    let rBottomRight = options.borderRadius || 0
    let rBottomLeft = options.borderRadius || 0

    proceed.call(this)

    if (rTopLeft || rTopRight || rBottomRight || rBottomLeft) {
      H.each(this.points, function (point) {
        let iBottomRight = rBottomRight

        let iBottomLeft = rBottomLeft

        let iTopRight = rTopRight

        let iTopLeft = rTopLeft

        if (typeof (curPercentage[point.index]) === 'undefined') {
          curPercentage[point.index] = 0
        }
        let prevPercentage = curPercentage[point.index]
        curPercentage[point.index] += 1.0 * parseFloat(point.percentage).toFixed(6)

        if (prevPercentage === 0 & curPercentage[point.index] === 100) {
          // special case, only one value > 0, preserve all border radius
          // reset for the next call
          curPercentage[point.index] = 0
        } else if (prevPercentage < 0.1) {
          // right side
          iBottomRight = 0
          iBottomLeft = 0
        } else if (curPercentage[point.index] > 99.9) {
          // left side
          iTopRight = 0
          iTopLeft = 0
          // reset for the next call
          curPercentage[point.index] = 0
        } else {
          // no radius
          iBottomRight = 0
          iBottomLeft = 0
          iTopRight = 0
          iTopLeft = 0
        }

        let shapeArgs = point.shapeArgs

        let w = shapeArgs.width

        let h = shapeArgs.height

        let x = shapeArgs.x

        let y = shapeArgs.y

        // Preserve the box for data labels
        point.dlBox = point.shapeArgs

        point.shapeType = 'path'
        point.shapeArgs = {
          d: [
            'M', x + iTopLeft, y,
            // top side
            'L', x + w - iTopRight, y,
            // top right corner
            'C', x + w - iTopRight / 2, y, x + w, y + iTopRight / 2, x + w, y + iTopRight,
            // right side
            'L', x + w, y + h - iBottomRight,
            // bottom right corner
            'C', x + w, y + h - iBottomRight / 2, x + w - iBottomRight / 2, y + h, x + w - iBottomRight, y + h,
            // bottom side
            'L', x + iBottomLeft, y + h,
            // bottom left corner
            'C', x + iBottomLeft / 2, y + h, x, y + h - iBottomLeft / 2, x, y + h - iBottomLeft,
            // left side
            'L', x, y + iTopLeft,
            // top left corner
            'C', x, y + iTopLeft / 2, x + iTopLeft / 2, y, x + iTopLeft, y,
            'Z'
          ]
        }
      })
    }
  })
}

export default () => {
  more(Highcharts)
  roundCorners(Highcharts)

  Highcharts.setOptions({
    credits: false,
    title: false,
    legend: false
  })
}
