<template>
  <div class="extract-bar" v-if="displayed">
    <div class="in-progress"  v-if="inProgress">
      <span class="loading">Extraction en cours</span>
      <span class="filename"> {{ currentExtract.filename }} </span>
    </div>
    <div class="done" v-if="done">
      <a :href="`/api/extracts/${this.currentExtract.extractId}/download`"
        class="white"
        @click="close"
        download>
        Télécharger le fichier
        <span class="filename"> {{ currentExtract.filename }} </span>
      </a>
      <a @click="clear" class="close"></a>
    </div>
    <div class="errored" v-if="errored">
      <span>⚠️ Erreur lors de la création du fichier</span>
      <span class="filename"> {{ currentExtract.filename }} </span>
      <a @click="clear" class="close"></a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'extract-bar',
  computed: {
    ...mapState(['currentExtract']),
    displayed () {
      return !this.closed && (this.inProgress || this.done)
    },
    closed () {
      return this.currentExtract?.closed || false
    },
    inProgress () {
      return this.currentExtract?.status === 'in_progress'
    },
    done () {
      return this.currentExtract?.status === 'success'
    },
    errored () {
      return this.currentExtract?.status === 'error'
    }
  },
  methods: {
    clear () {
      this.$store.dispatch('clearCurrentExtract')
    },
    close () {
      this.$store.commit('setCurrentExtract', null)
    }
  },
  async beforeCreate () {
    await this.$store.dispatch('retrieveCurrentExtract')
    if (this.inProgress) this.$store.dispatch('pollCurrentExtractStatus')
  }
}
</script>

<style lang="sass" scoped>
@import 'src/palette.sass'

.extract-bar
  position: fixed
  bottom: 0
  left: 0
  right: 0
  line-height: 40px
  text-align: center

.in-progress
  background-color: #ff9e00
  > .filename
    padding-left: 1em

.done
  background-color: #28a745
  a.white
    color: white

.errored
  color: white
  background-color: #b62468

.filename
  color: white
  font-size: 0.75em
  white-space: nowrap

.close
  position: absolute
  right: 16px
  top: 12px
  width: 16px
  height: 16px
  opacity: 0.75

  &:hover
    opacity: 1

  &:before, &:after
    position: absolute
    left: 10px
    content: ' '
    height: 14px
    width: 2px
    background-color: #333

  &:before
    transform: rotate(45deg)

  &:after
    transform: rotate(-45deg)
</style>
