import Vue from 'vue'

const getAllHolder = {}

const getByIdHolder = {}

const getEmployeesCountHolder = {}
const getEmployeesCumulatedHolder = {}

const getAdvantagesHolder = {}

const getRegistrationsPerDayHolder = {}
const getRolesRepartitionHolder = {}
const getFavoriteRoutesDistanceDistributionHolder = {}

const getGoingPolylinesHolder = {}
const getHubsHolder = {}
const getMatchingsDistributionHolder = {}
const getMatchingsCountDistributionHolder = {}

const getCofundedHolder = {}
const getCumulatedExchangedMessagesHolder = {}

const getCumulatedSponsoredEurosHolder = {}

const getTravelsHolder = {}
const getCumulatedSavedEurosHolder = {}

const getCumulatedIncentivesHolder = {}
const getIncentivesConsumptionHolder = {}

const paramsString = ({ startTime, endTime }) => {
  const params = new URLSearchParams()
  if (startTime) params.append('start_time', startTime.unix())
  if (endTime) params.append('end_time', endTime.unix())
  return params.toString().length > 0 ? `?${params}` : ''
}

export default {

  getAllByName (searchFilter, page = 0) {
    return Vue.$get(
      `/reporting_spaces?search_filter=${searchFilter}&page=${page}`,
      { holder: getAllHolder }
    )
  },

  getById (id) {
    return Vue.$get(
      `/reporting_spaces/${id}`,
      { holder: getByIdHolder }
    )
  },

  getEmployeesCount (id, params) {
    return Vue.$get(
      `/reporting_spaces/${id}/employees/count${paramsString(params)}`,
      { holder: getEmployeesCountHolder }
    )
  },

  getEmployeesCumulated (id, params) {
    return Vue.$get(
      `/reporting_spaces/${id}/employees/cumulated${paramsString(params)}`,
      { holder: getEmployeesCumulatedHolder }
    )
  },

  getAdvantages (id) {
    return Vue.$get(
      `/reporting_spaces/${id}/advantages`,
      { holder: getAdvantagesHolder }
    )
  },

  getRegistrationsPerDay (id, params) {
    return Vue.$get(
      `/reporting_spaces/${id}/employees/registrations_per_day${paramsString(params)}`,
      { holder: getRegistrationsPerDayHolder }
    )
  },

  getRolesRepartition (id, params) {
    return Vue.$get(
      `/reporting_spaces/${id}/employees/roles_repartition${paramsString(params)}`,
      { holder: getRolesRepartitionHolder }
    )
  },

  getFavoriteRoutesDistanceDistribution (id, params) {
    return Vue.$get(
      `/reporting_spaces/${id}/employees/favorite_routes_distance_distribution${paramsString(params)}`,
      { holder: getFavoriteRoutesDistanceDistributionHolder }
    )
  },

  getGoingPolylines (id, params) {
    return Vue.$get(
      `/reporting_spaces/${id}/employees/going_polylines${paramsString(params)}`,
      { holder: getGoingPolylinesHolder }
    )
  },

  getHubs (id, params) {
    return Vue.$get(
      `/reporting_spaces/${id}/employees/driver_hubs${paramsString(params)}`,
      { holder: getHubsHolder }
    )
  },

  getMatchingsDistribution (id, params) {
    return Vue.$get(
      `/reporting_spaces/${id}/employees/matchings_distribution${paramsString(params)}`,
      { holder: getMatchingsDistributionHolder }
    )
  },

  getMatchingsCountDistribution (id, params) {
    return Vue.$get(
      `/reporting_spaces/${id}/employees/matchings_count_distribution${paramsString(params)}`,
      { holder: getMatchingsCountDistributionHolder }
    )
  },

  getCofunded (id, params) {
    return Vue.$get(
      `/reporting_spaces/${id}/employees/sponsored${paramsString(params)}`,
      { holder: getCofundedHolder }
    )
  },

  getCumulatedExchangedMessages (id, params) {
    return Vue.$get(
      `/reporting_spaces/${id}/employees/cumulated_exchanged_messages${paramsString(params)}`,
      { holder: getCumulatedExchangedMessagesHolder }
    )
  },

  getCumulatedSponsoredEuros (id, params) {
    return Vue.$get(
      `/reporting_spaces/${id}/employees/cumulated_sponsored_euros${paramsString(params)}`,
      { holder: getCumulatedSponsoredEurosHolder }
    )
  },

  getTravels (id, params) {
    return Vue.$get(
      `/reporting_spaces/${id}/employees/travels${paramsString(params)}`,
      { holder: getTravelsHolder }
    )
  },

  getCumulatedSavedEuros (id, params) {
    return Vue.$get(
      `/reporting_spaces/${id}/employees/cumulated_saved_euros${paramsString(params)}`,
      { holder: getCumulatedSavedEurosHolder }
    )
  },

  getCumulatedIncentives (id, months, period) {
    const params = new URLSearchParams()
    params.append('months', months)
    params.append('period', period)

    return Vue.$get(
      `/reporting_spaces/${id}/incentives/cumulated?${params}`,
      { holder: getCumulatedIncentivesHolder }
    )
  },

  getIncentivesConsumption (id, months, period) {
    const params = new URLSearchParams()
    params.append('months', months)
    params.append('period', period)

    return Vue.$get(
      `/reporting_spaces/${id}/incentives/consumption?${params}`,
      { holder: getIncentivesConsumptionHolder }
    )
  }
}
