import Vue from 'vue'
import bugsnag from '@bugsnag/js'
import store from './store'

/**
 *
 * @param number
 * @param tiny ensure the number has a 5 characters representation if lower than 1 billion
 * @param space separation between thousands, an empty string will make no separation
 * @returns {string}
 */
export function formatNumber (number, tiny = false, space = ' ') {
  let unit = ''
  if (tiny) {
    if (number > 999999) {
      unit = 'M'
      number = Math.round(number / 1000000)
    } else if (number > 9999) {
      unit = 'K'
      number = Math.round(number / 1000)
    }
  }

  // credits: https://stackoverflow.com/a/14428340/6320039
  return number.toString().replace(/\d(?=(\d{3})+$)/g, `$&${space}`) + unit
}

export function supportsFlexBox () {
  const test = document.createElement('test')
  test.style.display = 'flex'
  return test.style.display === 'flex'
}

export const cancelMessage = 'Old operation cancelled, taking new one into account.'

let bugsnagClient = null
export const getBugsnagClient = async () => {
  if (process.env.VUE_APP_BUGSNAG_API_KEY == null) {
    return null
  }
  if (!bugsnagClient) {
    try {
      const response = await Vue.$get('/stage')
      bugsnagClient = bugsnag({
        apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
        appVersion: process.env.VUE_APP_VERSION,
        releaseStage: response.env
      })
    } catch (error) {
      bugsnagClient = bugsnag({
        apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
        appVersion: process.env.VUE_APP_VERSION
      })
      bugsnagClient.notify(error, { severity: 'error' })
    }
    // Add state tab
    bugsnagClient.metaData = {
      get state () { return { state: store.state } }
    }
  }
  return bugsnagClient
}

export function camelToSnake (string) {
  return string.replace(/([A-Z])/g, (_, char) => `_${char.toLowerCase()}`)
}
