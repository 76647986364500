import { getBugsnagClient, cancelMessage } from '../utils'
import axios from 'axios'
import axiosRetry from 'axios-retry'

// -----------------------------------------
//  Helpers.
// -----------------------------------------

const snakeToCamel = string => (
  string.replace(/_([a-z])/g, (_, char) => char.toUpperCase())
)

const sanitize = object => {
  if (object instanceof Array) return object.map(sanitize)
  if (!(object instanceof Object)) return object
  const camel = {}
  Object.keys(object).forEach((key) => {
    camel[snakeToCamel(key)] = sanitize(object[key])
  })
  return camel
}

const bugsnag = async (error) => {
  const bugsnagClient = await getBugsnagClient()
  bugsnagClient && bugsnagClient.notify(error, { severity: 'error' })
}

// -----------------------------------------
//  Configure Axios.
// -----------------------------------------

axiosRetry(
  axios,
  {
    retries: 3,
    retryCondition: error => (
      error && error.response && error.response.status === 503
    )
  }
)

// Force baseURL to '/' instead of process.env.BASE_URL because the CDN
// configuration from vue.config.js would configure it wrong.
// But we want to keep the `devServer.proxy` config from the same file
// because it's needed when running the app with `make serve`.
let baseUrl = '/'
if (process.env.BASE_URL === 'http://localhost:3002') {
  baseUrl = process.env.BASE_URL
}
axios.defaults.baseURL = `${baseUrl}api/`
axios.defaults.withCredentials = true

// -----------------------------------------
//  Expose the initializer.
// -----------------------------------------

export default ({ Vue, store, router }) => {
  // Handle incrementActiveRequests/decrementActiveRequests
  axios.defaults.transformRequest.push(data => {
    store.commit('incrementActiveRequests')
    return data
  })
  axios.defaults.transformResponse.push(data => {
    store.commit('decrementActiveRequests')
    if (data.result == null) return data
    return sanitize(data.result)
  })
  // Handle HTTP errors
  axios.interceptors.response.use(
    // Handle success responses
    response => response.data,
    // Handle errors
    async error => {
      // A cancelled request is not an error
      if (error.message === cancelMessage) return
      // Interpret the error
      switch (error.response && error.response.status) {
        // Logged out
        case 401:
          store.dispatch('logout')
          // There can be many simultaneous requests, we ensure that only the first one redirects
          // to avoid having a weird `/login?redirect=/login`.
          if (!router.currentRoute.path.startsWith('/login')) {
            window.location.href = `/login?redirect=${encodeURI(router.currentRoute.path)}`
          }
          break
        // Not found
        case 404:
          // Generic fallback, put in place because of many request with a
          // reporting_space to undefined, (/reporting_space/undefined/)
          // If a similar problem occurs, we inform the user of the  problem and
          // redirect it to the index page
          Vue.notify(
            `
              Un problème est survenu, impossible de récupérer la ressource.
              Une notification de bug a été envoyé. Vous allez être redirigé vers la page d'accueil.
              Si le problème persiste, veuillez vous reconnecter et contacter un administrateur.
            `
          )
          await new Promise(resolve => setTimeout(() => resolve(), 5000))
          window.location.href = '/'
          break
        // Resource Gone
        case 410:
          // This kind of error is quite normal and could be ignored safely. They happen with extracts
          // (which can timeout).
          Vue.notify('La ressource demandée n\'est plus disponible. Merci de réiterer votre requête.')
          break
        // Rack Timeout
        case 503:
          Vue.notify(
            'Certaines données sont temporairement indisponibles. Vous pouvez essayer de recharger cette page. '
            + 'Si l\'erreur persiste, contactez nous sur'
            + '<a href="mailto:serviceclients@klaxit.com?subject=Erreur Inconnue sur Klaxit Insights">serviceclients@klaxit.com</a>'
          )
          break
        // Assume 500
        default:
          Vue.notify(
            'Une erreur inconnue est survenue, vous pouvez essayer de recharger la page ou de vous reconnecter. '
            + 'Si celle-ci persiste, contactez nous sur '
            + '<a href="mailto:serviceclients@klaxit.com?subject=Erreur Inconnue sur Klaxit Insights">serviceclients@klaxit.com</a>'
          )
          bugsnag(error)
      }
      return Promise.reject(error)
    }
  )
  // Global request method wrapper
  const request = async (method, endpoint, data = {}, options = {}) => {
    // Prepare axios options
    let axiosOptions = {}
    if (options.holder) {
      // Cancel the previous request
      if (options.holder.cancel != null) {
        if (typeof options.holder.cancel !== 'function') {
          throw new Error('holder.cancel is not a function')
        }
        store.commit('decrementActiveRequests')
        options.holder.cancel(cancelMessage)
        options.holder.cancel = null
      }
      // Init the cancellor
      axiosOptions.cancelToken = new axios.CancelToken(function executor (c) {
        options.holder.cancel = c
      })
    }
    // Send the request
    let result
    try {
      switch (method.toUpperCase()) {
        case 'GET':
          result = await axios.get(endpoint, axiosOptions)
          break
        case 'POST':
          result = await axios.post(endpoint, data, axiosOptions)
          break
        default:
          throw new Error('This method is not handled yet. Please implement it.')
      }
    } catch (e) {
      bugsnag(e)
      return null
    }
    // Remove the cancellor
    if (options.holder) {
      options.holder.cancel = null
    }
    // Return the result data
    return result
  }
  // Expose get/post methods
  Vue.$get = Vue.prototype.$get = async (endpoint, options = {}) => {
    return request('GET', endpoint, {}, options)
  }
  Vue.$post = Vue.prototype.$post = async (endpoint, data = {}, options = {}) => {
    return request('POST', endpoint, data, options)
  }
}
