import { formatNumber } from '../utils'

export default ({ Vue }) => {
  Vue.filter('number', function (value, tiny = false, space = ' ') {
    if (typeof value !== 'number') return value

    return formatNumber(value, tiny, space)
  })

  Vue.filter('currency', function (cents, signDisplay = 'auto') {
    if (typeof cents !== 'number') return cents
    var formatter = new Intl.NumberFormat(
      'fr-FR', {
        style: 'currency',
        currency: 'EUR',
        signDisplay: signDisplay
      })
    return formatter.format(cents / 100)
  })
}
