import bugsnagVue from '@bugsnag/plugin-vue'
import { getBugsnagClient } from '../utils'

export default ({ Vue, store }) => {
  getBugsnagClient().then(bugsnagClient => {
    if (!bugsnagClient) {
      /* eslint-disable no-console */
      console.log('⚠️ Bugsnag API key has not been defined...')
      /* eslint-enable no-console */
      return
    }
    bugsnagClient.use(bugsnagVue, Vue)
    bugsnagClient.user = {
      name: store.getters.fullName,
      email: store.state.email
    }
  })
}
