import Notifications from 'vue-notification'

export default ({ Vue }) => {
  Vue.use(Notifications)

  // It is not possible to redefine the default parameters of the component,
  // so we overwrite the method to define this default parameters
  const originalVueNotify = Vue.notify
  Vue.notify = function (config) {
    if (typeof config === 'string') {
      config = { text: config }
    }
    if (!('ignoreDuplicates' in config)) {
      config.ignoreDuplicates = true
    }
    originalVueNotify(config)
  }
}
