import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AntdInitializer from './initializers/antd'
import AxiosInitializer from './initializers/axios'
import BugsnagInitializer from './initializers/bugsnag'
import FiltersInitializer from './initializers/filters'
import HighchartsInitializer from './initializers/highcharts'
import MomentInitializer from './initializers/moment'
import NotificationsInitializer from './initializers/notifications'
import PaletteInitializer from './initializers/palette'
import PolyfillsInitializer from './initializers/polyfills'

AntdInitializer({ Vue })
AxiosInitializer({ Vue, store, router })
BugsnagInitializer({ Vue, store })
FiltersInitializer({ Vue })
HighchartsInitializer()
MomentInitializer({ Vue })
NotificationsInitializer({ Vue })
PaletteInitializer({ Vue })
PolyfillsInitializer()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
