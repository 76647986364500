<template>
  <div class="klaxit app" :class="{ 'no-flexbox': !supportsFlexBox }">
    <router-view></router-view>
    <extract-bar v-if="loggedIn"></extract-bar>
  </div>
</template>

<script>
import { supportsFlexBox } from 'src/utils'
import { mapGetters } from 'vuex'
import ExtractBar from 'src/components/ExtractBar.vue'

export default {
  name: 'app',
  data () {
    return {
      supportsFlexBox: supportsFlexBox()
    }
  },
  computed: {
    ...mapGetters(['loggedIn'])
  },
  components: {
    ExtractBar
  }
}
</script>

<style lang="sass">
@import 'src/palette.sass'

// The fonts were generated using https://www.fontsquirrel.com/tools/webfont-generator and the original ttf files you can find
// in `src/assets/fonts/*.ttf`
@font-face
  font-family: 'Open Sans'
  src: url('./assets/fonts/opensans-bold-webfont.woff2') format('woff2'), url('./assets/fonts/opensans-bold-webfont.woff') format('woff')
  font-weight: 700

@font-face
  font-family: 'Open Sans'
  src: url('./assets/fonts/opensans-light-webfont.woff2') format('woff2'), url('./assets/fonts/opensans-light-webfont.woff') format('woff')
  font-weight: 300
  font-style: normal

@font-face
  font-family: 'Open Sans'
  src: url('./assets/fonts/opensans-semibold-webfont.woff2') format('woff2'), url('./assets/fonts/opensans-semibold-webfont.woff') format('woff')
  font-weight: 500
  font-style: normal

a.accent
  color: $accent-color
  &:active
    color: darken($accent-color, 5%)
  &:hover
    color: lighten($accent-color, 20%)
.error
  color: $error-color
.klaxit
  overflow: hidden
  position: relative
  min-width: 1020px
  min-height: 100%
.header
  margin: 40px 10px 0
  h1
    float: left
    font-size: 22px
    font-weight: normal
  *
    // TODO: check if this is useful
    vertical-align: middle
*
  font-family: "Open Sans", sans-serif
.klaxit
  color: #393c40
ul, li
  margin-left: 0
  padding-left: 0
  display: block
  list-style-type: none
h1
  font-weight: normal
  font-size: 24px
.tac
  text-align: center
.pull-right
  float: right
.pull-left
  float: left
.container
  // some air
  padding-bottom: 40px
  width: 800px
  margin: 0 auto
  position: relative

.loading:after
  content: ' .'
  animation: dots 1s steps(5, end) infinite

@keyframes dots
  0%, 20%
    color: rgba(0, 0, 0, 0)
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0)

  40%
    color: #393c40
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0)

  60%
    text-shadow: 0.25em 0 0 #393c40, 0.5em 0 0 rgba(0, 0, 0, 0)

  80%, 100%
    text-shadow: .25em 0 0 #393c40, .5em 0 0 #393c40

// -------------------------------------------------------------- Ant Design Vue
.klaxit
  .ant-layout
    min-height: 100vh
.ant-tag-blue
  color: $accent-color !important
  background: lighten($accent-color, 48) !important
  border-color: lighten($accent-color, 10) !important
.no-flexbox
  .ant-layout.ant-layout-has-sider
    position: relative
    height: 100%
    .ant-layout-sider
      position: absolute
      height: 100%
    .ant-layout-content
      margin-left: 264px
      @media(max-width: 1200px)
          margin-left: 84px
// ------------------------------------------------------------ Vue Notification
.klaxit .notification-wrapper
  border-bottom: 1px inset #979797
  position: relative

.klaxit .notifications
  width: 100% !important
  top: initial !important
  right: initial !important
  position: initial
  .notification-content
    color: $white

.klaxit .vue-notification
  font-size: 14px
  margin: 0
  border: none
  text-align: center
  background: $notification-color

// fixes width issue https://github.com/euvl/vue-notification/issues/188
.klaxit .vue-notification-group
  width: 100% !important
</style>
