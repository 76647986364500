import Vue from 'vue'

const currentExtractHolder = {}

export default {
  getCurrentExtract () {
    return Vue.$get('/extracts/current', { holder: currentExtractHolder })
  },
  async getExtractStatus (id) {
    const data = await Vue.$get(`/extracts/${id}/status`)
    return data.status
  },
  clearExtract (id) {
    return Vue.$post(`/extracts/${id}/clear`)
  },
  pollExtractStatus (id) {
    if (this.onPollExtractStatus) return
    this.onPollExtractStatus = true
    return new Promise(
      (resolve, reject) => {
        const poll = () => {
          setTimeout(
            async () => {
              let status = 'error'
              try {
                status = await this.getExtractStatus(id)
              } catch (e) { }
              if (status === 'in_progress') return poll()
              this.onPollExtractStatus = false
              resolve(status)
            },
            500
          )
        }
        poll()
      }
    )
  }
}
