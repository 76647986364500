import 'ant-design-vue/es/button/style/index.less'
import 'ant-design-vue/es/avatar/style/index.less'
import 'ant-design-vue/es/card/style/index.less'
import 'ant-design-vue/es/checkbox/style/index.less'
import 'ant-design-vue/es/col/style/index.js'
import 'ant-design-vue/es/date-picker/style/index.less'
import 'ant-design-vue/es/dropdown/style/index.less'
import 'ant-design-vue/es/form/style/index.less'
import 'ant-design-vue/es/icon/style/index.js'
import 'ant-design-vue/es/input/style/index.less'
import 'ant-design-vue/es/input-number/style/index.less'
import 'ant-design-vue/es/layout/style/index.less'
import 'ant-design-vue/es/menu/style/index.less'
import 'ant-design-vue/es/popover/style/index.less'
import 'ant-design-vue/es/progress/style/index.less'
import 'ant-design-vue/es/radio/style/index.less'
import 'ant-design-vue/es/row/style/index.js'
import 'ant-design-vue/es/select/style/index.less'
import 'ant-design-vue/es/spin/style/index.less'
import 'ant-design-vue/es/table/style/index.less'
import 'ant-design-vue/es/tag/style/index.less'
import 'ant-design-vue/es/time-picker/style/index.less'
import 'ant-design-vue/es/tooltip/style/index.less'

import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  Menu,
  Popover,
  Progress,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  TimePicker,
  Tooltip
} from 'ant-design-vue'

export default ({ Vue }) => {
  Vue.component(Avatar.name, Avatar)
  Vue.component(Button.name, Button)
  Vue.component(Card.name, Card)
  Vue.component(Checkbox.name, Checkbox)
  Vue.component(Col.name, Col)
  Vue.component(DatePicker.name, DatePicker)
  Vue.component(DatePicker.RangePicker.name, DatePicker.RangePicker)
  Vue.component(Dropdown.name, Dropdown)
  Vue.component(Form.name, Form)
  Vue.component(Form.Item.name, Form.Item)
  Vue.component(Icon.name, Icon)
  Vue.component(Input.name, Input)
  Vue.component(InputNumber.name, InputNumber)
  Vue.component(Layout.name, Layout)
  Vue.component(Layout.Header.name, Layout.Header)
  Vue.component(Layout.Content.name, Layout.Content)
  Vue.component(Layout.Sider.name, Layout.Sider)
  Vue.component(Menu.name, Menu)
  Vue.component(Menu.Item.name, Menu.Item)
  Vue.component(Menu.Divider.name, Menu.Divider)
  Vue.component(Popover.name, Popover)
  Vue.component(Progress.name, Progress)
  Vue.component(Radio.Button.name, Radio.Button)
  Vue.component(Radio.Group.name, Radio.Group)
  Vue.component(Row.name, Row)
  Vue.component(Select.name, Select)
  Vue.component(Select.OptGroup.name, Select.OptGroup)
  Vue.component(Select.Option.name, Select.Option)
  Vue.component(Spin.name, Spin)
  Vue.component(Table.name, Table)
  Vue.component(TimePicker.name, TimePicker)
  Vue.component(Tooltip.name, Tooltip)
}
